<template>
  <default-layout :title="`Project: ${projectName}`">
    <v-container fluid tag="section">
      <unit-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const UnitForm = () => import('@/components/project-management/unit/form/unit-form');

export default {
  name: 'unit-type-add',
  components: { DefaultLayout, UnitForm },
  async created() {
    await this.$store.dispatch('unit/form/restoreInitialState');
    this.$store.commit('unit/form/SET_FORM_TYPE', 'ADD');
    if (this.$route.query.project_uuid) {
      this.$store.commit('unit/form/SET_PROJECT_UUID', this.$route.query.project_uuid);

      this.$store.dispatch('unit/form/fetchBaseData', {});
    } else if (this.$route.query.unit_category_id) {
      this.$store.dispatch('unit/form/fetchBaseData', {
        unit_category_id: this.$route.query.unit_category_id,
      });
    } else if (this.$route.query.project_unit_type_uuid) {
      this.$store.dispatch('unit/form/fetchBaseData', {
        project_unit_type_uuid: this.$route.query.project_unit_type_uuid,
      });
    }
  },
  computed: {
    projectName() {
      return this.$store.state.unit.form.projectName;
    },
  },
};
</script>

<style scoped></style>
